import axios from 'axios';
import wrapWithProvider from './wrap-with-provider'

import { makeServer } from "./server";

import './src/styles/global.css';
import 'lity/dist/lity.min';
import 'lity/dist/lity.min.css';
import 'jquery/dist/jquery.min';
import 'scrollmagic/scrollmagic/minified/ScrollMagic.min';

export const onInitialClientRender = () => {
  axios
  .get('https://ipapi.co/json/')
  .then((response) => {
    let data = response.data;

    if (data.in_eu) {
      var ref = window.document.getElementsByTagName( 'script' )[ 0 ];
      var script = window.document.createElement( 'script' );
      script.src = 'https://consent.cookiebot.com/uc.js';
      script.id = 'Cookiebot'
      script.dataset.cbid = '1da39537-8508-4100-ae9b-0d8450462196';
      script.dataset.blockingmode = 'auto';
      ref.parentNode.insertBefore( script, ref );
    }
  })
  .catch((error) => {
    console.log(error);
  });
}

export const wrapRootElement = wrapWithProvider;

/*
if (process.env.NODE_ENV === 'development') {
  makeServer({ environment: 'development' })
}*/
