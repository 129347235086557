import React from 'react';

import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import createStore from './src/redux/store';

export default ({ element }) => {
  const store = createStore();

  return (
    <Provider store={store}>
      <ToastContainer />
      {element}
    </Provider>
  );
}
