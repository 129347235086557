import { combineEpics } from 'redux-observable';

import fetchConfigEpic from './fetchConfigEpic';
import sendUserDetailsFormEpic from './sendUserDetailsFormEpic';
import sendPaymentDetailsFormEpic from './sendPaymentDetailsFormEpic';
import payUpsellEpic from './payUpsellEpic';

export default combineEpics(
  fetchConfigEpic,
  sendUserDetailsFormEpic,
  sendPaymentDetailsFormEpic,
  payUpsellEpic
);