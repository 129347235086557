import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  paymentType: '',
  trialInDays: null,
  customContent: {},
  userDetails: {},
  sendingDetails: false,
  loading: true,
  redirectUrl: '',
  special: false
}

const slice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    fetchConfig(state, action) {
      state.loading = true;
    },
    fetchConfigSuccess: (state, action) => ({
      ...state,
      ...action.payload,
      loading: false
    }),
    sendUserDetailsForm(state, action) {
      state.sendingDetails = true;
    },
    sendUserDetailsFormSuccess: (state, action) => ({
      ...state,
      userDetails: action.payload,
      sendingDetails: false
    }),
    setUserDetails: (state, action) => ({
      ...state,
      userDetails: action.payload,
    }),
    sendUserDetailsFormError(state) {
      state.sendingDetails = false;
    },
    sendPaymentDetailsForm(state, action) {
      state.sendingDetails = true;
    },
    sendPaymentDetailsFormSuccess: (state, action) => ({
      ...state,
      redirectUrl: action.payload,
      sendingDetails: false
    }),
    changeSelectedPaymentType: (state, action) => ({
      ...state,
      paymentType: action.payload,
    }),
    sendPaymentDetailsFormError(state) {
      state.sendingDetails = false;
    },
    sendLoginRedirect: (state, action) => ({
      ...state,
      redirectUrl: action.payload
    }),
    payUpsell(state, action) {
      state.payingUpsell = true;
    },
    payUpsellSuccess: (state, action) => ({
      ...state,
      redirectUrl: action.payload,
      payingUpsell: false
    }),
    payUpsellError(state) {
      state.payingUpsell = false;
    },
  }
});

export default slice;
