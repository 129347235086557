import slice from './slices';

export const {
  fetchConfig,
  fetchConfigSuccess,
  sendUserDetailsForm,
  sendUserDetailsFormSuccess,
  sendUserDetailsFormError,
  sendPaymentDetailsForm,
  sendPaymentDetailsFormSuccess,
  sendLoginRedirect,
  sendPaymentDetailsFormError,
  changeSelectedPaymentType,
  setUserDetails,
  payUpsell,
  payUpsellSuccess,
  payUpsellError
} = slice.actions;

export default slice.reducer;