import axios from 'axios';
import { of, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { fetchConfig, fetchConfigSuccess } from '../store';
import { callDataLayer } from '../utils/dataLayer';

const fetchConfigAjax$ = (plan, special, priceOff, forceTrialInDays) => {
  const trials = forceTrialInDays !== false ? `&forceTrialInDays=${forceTrialInDays}` : '';
  return from(
    axios.get(
      `${process.env.API_ENDPOINT}${
        special ? '/fetch-special-signup-json/' : '/plans/fetch-signup-json/'
      }${plan}/?priceOff=${priceOff}${trials}`,
      { headers: { 'Content-Type': 'application/json', Accept: 'application/json' } }
    )
  );
};

const fetchConfigEpic = (action$) =>
  action$.pipe(
    ofType(fetchConfig),
    mergeMap(({ payload: { plan, special, priceOff, forceTrialInDays } }) =>
      fetchConfigAjax$(plan, special, priceOff, forceTrialInDays).pipe(
        mergeMap(({ data }) => {
          const dataLayerObj = {
            event: 'conversion',
            'event-type': 'InitiateCheckout',
            'content-name': 'Deadline Funnel Signup',
            'content-category': 'deadline-funnel',
          };
          callDataLayer(dataLayerObj);
          return of(
            fetchConfigSuccess(
              special ? { customContent: data, special, trialInDays: data.trialInDays } : { ...data, special }
            )
          );
        })
      )
    )
  );

export default fetchConfigEpic;
