export const options = {
  className: 'success-toast',
  autoClose: 2000,
  bodyClassName: 'grow-font-size',
  hideProgressBar: true,
  closeButton: false
}

export const successOptions = {
  className: 'green-toast',
  autoClose: 5000,
  bodyClassName: 'grow-font-size',
  hideProgressBar: true,
  closeButton: false
}

export const errorOptions = {
  className: 'error-toast',
  autoClose: 4000,
  bodyClassName: 'grow-font-size',
  hideProgressBar: true,
  closeButton: false
}

export const optionsCenter = {
  position: 'top-center',
  className: 'success-toast',
  autoClose: 10000,
  bodyClassName: 'grow-font-size',
  hideProgressBar: true,
  closeButton: false,
};
