import axios from 'axios';
import cookie from 'js-cookie';
import { toast } from 'react-toastify';
import { of, from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import {
  sendUserDetailsForm,
  sendUserDetailsFormSuccess,
  sendUserDetailsFormError,
  sendLoginRedirect,
  sendPaymentDetailsFormSuccess,
} from '../store';
import { callDataLayer } from '../utils/dataLayer';
import { errorOptions } from '../../../config/toastOptions';
import { getAppDomain } from '../utils/getAppDomain';
import { requestTokensAjax$ } from './ajax/requestTokensAjax';

const sendUserDetailsFormAjax$ = (form) =>
  from(axios.post(`${process.env.API_ENDPOINT}/api/signup${form.funnel === 'portal' ? '/freemium' : ''}`, { ...form, taf: cookie.get('taf') }));

const sendUserDetailsFormEpic = (action$) =>
  action$.pipe(
    ofType(sendUserDetailsForm),
    mergeMap(({ payload }) =>
      sendUserDetailsFormAjax$(payload).pipe(
        mergeMap(({ data }) => {
          const dataLayerObj = {
            event: 'conversion',
            'event-type': 'SubmitNameAndEmail',
            'content-name': 'Deadline Funnel Signup',
            'content-category': 'deadline-funnel',
            funnel: payload.funnel,
          };
          callDataLayer(dataLayerObj);

          if (payload.funnel === 'no-cc') {
            return requestTokensAjax$(payload).pipe(
              mergeMap(({ data: response }) => {
                cookie.set('DFToken', response.access_token, { domain: getAppDomain() });
                cookie.set('DFRefreshToken', response.refresh_token, { domain: getAppDomain() });

                return of(sendLoginRedirect(data.redirectUrl));
              })
            );
          }

          return of(
            sendUserDetailsFormSuccess({
              ...data,
              user: { ...data.user, password: payload.password },
              firstStepForm: {
                how_did_you_hear_about_us: payload.how_did_you_hear_about_us,
                business_type: payload.business_type,
              },
            })
          );
        }),
        catchError(({ response: { data } }) => {
          const errorsText = data.errors
            ? Object.values(data.errors)
                .map((error) => `${error[0]}`)
                .join(' ')
            : '';
          toast.error(`${data.message || ''} ${errorsText}`, errorOptions);
          window.grecaptcha.reset();
          return of(sendUserDetailsFormError());
        })
      )
    )
  );

export default sendUserDetailsFormEpic;
