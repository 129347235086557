import axios from 'axios';
import cookie from 'js-cookie';
import { toast } from 'react-toastify';
import { of, from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { payUpsell, payUpsellSuccess, payUpsellError } from '../store';
import { errorOptions } from '../../../config/toastOptions';

const payUpsellAjax$ = data =>
  from(axios.post(`${process.env.API_ENDPOINT}/api/process-upsell`, { ...data }));

const payUpsellEpic = (action$, state$) =>
  action$.pipe(
    ofType(payUpsell),
    mergeMap(({ payload }) =>
      payUpsellAjax$().pipe(
        mergeMap(({ data }) => {
          if (data.error) {
            throw data.error;
          }

          const matches = process.env.APP_URL.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
          let domain = matches && matches[1];
          const dots = domain.match(/[.]/g);
          if (dots && dots.length > 1) {
            domain = domain.replace(/^[^.]+\./g, "");
          }
          cookie.set('DFToken', data.token, { path: '/', domain });

          return of(payUpsellSuccess(data.redirectUrl))
        }),
        catchError((errorsText) => {
          toast.error(`${errorsText}`, errorOptions);
          return of(payUpsellError());
        })
      )
    )
  );

export default payUpsellEpic;
