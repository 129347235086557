import axios from 'axios';
import cookie from 'js-cookie';
import { toast } from 'react-toastify';
import { of, from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { sendPaymentDetailsForm, sendPaymentDetailsFormSuccess, sendPaymentDetailsFormError } from '../store';
import { errorOptions } from '../../../config/toastOptions';
import { callDataLayer } from '../utils/dataLayer';
import { getAppDomain } from '../utils/getAppDomain';
import { requestTokensAjax$ } from './ajax/requestTokensAjax';

const sendPaymentDetailsFormAjax$ = data =>
  from(axios.post(`${process.env.API_ENDPOINT}/api/signup/billing`, { ...data }));

const addDataFromReduxState = (data, state) => ({
  ...data,
  u_hash: state.userDetails.u_hash
});

const sendPaymentDetailsFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(sendPaymentDetailsForm),
    mergeMap(({ payload }) =>
      sendPaymentDetailsFormAjax$(addDataFromReduxState(payload, state$.value.signup)).pipe(
        mergeMap(({ data }) => {
          if (data.error) throw data.error;

          callDataLayer({
            'event': 'conversion',
            'event-type': 'TrialSignup',
            'content-name': 'Deadline Funnel Signup',
            'content-category': 'deadline-funnel',
            'plan': payload.plan,
            'billing-period': payload.paymentType,
            'funnel': payload.funnel ?? 'cc',
            'email': payload.email
          });

          window.rewardful = window.rewardful || [];
          try {
            window.rewardful('convert', { email: payload.email });
          } catch (err) {
            console.log("Rewardful not found");
          }

          if (payload.funnel === 'no-cc') {
            return of(sendPaymentDetailsFormSuccess("https://www.deadlinefunnel.com/success"));
          } else {
            return requestTokensAjax$(state$.value.signup.userDetails.user).pipe(
              mergeMap(({ data: response }) => {
                cookie.set('DFToken', response.access_token, { domain: getAppDomain() });
                cookie.set('DFRefreshToken', response.refresh_token, { domain: getAppDomain() });
  
                return of(sendPaymentDetailsFormSuccess(data.redirectUrl))
              })
            )
          }

          
        }),
        catchError((errorsText) => {
          toast.error(`${errorsText}`, errorOptions);
          return of(sendPaymentDetailsFormError());
        })
      )
    )
  );

export default sendPaymentDetailsFormEpic;
